<template>
    <div class="label-box-container" :class="[$mq, 'col-' + source.columns]">
        <!-- :class="labelClass(label)" -->
        <template v-if="source">
            <div v-for="label in source.items" :key="label.id" class="label" @click="select(label)">
                <span
                    class="color"
                    v-if="source.show_cat"
                    v-bind:style="{
                        backgroundColor: categories[label.category_id].color
                    }"
                ></span>
                <div v-if="!isApp" class="image">
                    <cld-image class="image-cloud" :publicId="label.image ? label.image : apiURL + 'static/images/offline/no_icon_light.svg'" type="fetch" loading="lazy">
                        <cld-transformation height="100" crop="fill" fetchFormat="webp" />
                    </cld-image>
                </div>
                <div
                    v-else
                    class="imageapp"
                    :class="{ appempty: !label.local_image && !label.image }"
                    :style="{
                        backgroundImage: 'url(' + (label.local_image ? label.local_image : label.image) + ')'
                    }"
                ></div>
                <div class="label-text">{{ label.name }}</div>
            </div>
        </template>
        <p v-if="!source" class="empty">
            {{ $t('label.empty_labels') }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'LabelItem',
    props: {
        index: {
            type: Number
        },
        source: {
            // here is: {uid: 'unique_1', text: 'abc'}
            type: Object,
            default() {
                return {}
            }
        },
        label: {
            type: undefined,
            default: false
        },
        show: {
            type: Boolean,
            default: false
        },
        showBall: {
            type: undefined,
            default: false
        },
        type: {
            type: String,
            default: 'label'
        }
    },
    data() {
        return {
            // label: this.label
        }
    },
    methods: {
        select(label) {
            label.index = this.index
            this.$root.$emit('label', label)
        }
    },
    computed: {
        //
        categories() {
            return this.$store.getters[this.type + '/getCategories']
        },
        apiURL() {
            return this.$store.getters['login/getAPIurl']
        },
        isApp() {
            var bIsApp = process.env.VUE_APP_IS_APP == 'TRUE'
            return bIsApp
        }
    }
}
</script>

<style lang="scss" scoped>
$icon-size: rem(60px);

.label-box-container {
    width: 100%;
    height: 100%;
    max-height: 145px;
    min-height: 145px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;

    &.col-5 {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    &.col-4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    &.col-3 {
        grid-template-columns: 1fr 1fr 1fr;
    }
    &.col-2 {
        grid-template-columns: 1fr 1fr;
    }
    &.col-1 {
        grid-template-columns: 1fr;
    }

    // max-height: none;

    .label {
        @include border-radius(5px);
        @include display-flex();
        @include flex-direction(column);
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 5px;
        position: relative;
        cursor: pointer;
        overflow: hidden;

        .color {
            @include border-radius(10px);
            width: 18px;
            height: 18px;
            position: absolute;
            top: 5px;
            left: 5px;
        }

        .image {
            display: block;
            margin: 10px auto;
            text-align: center;
            height: 60%;
            margin-top: 3px;
            padding: 15px;

            img {
                height: 100%;
                object-fit: contain;
            }
        }

        .label-text {
            @include font-size(sm);
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
            font-family: $text-bold;
            text-align: center;
            color: $main-s90;
            width: 100%;
            height: 30%;
            line-height: 0.9rem;
        }
        .imageapp {
            @include background($image: img('offline/no_icon_light.svg'));
            display: block;
            margin: 10px auto;
            text-align: center;
            height: 60%;
            margin-top: 3px;
            padding: 15px;
            width: 60%;
            img {
                height: 100%;
                object-fit: contain;
            }

            &.appempty {
                @include background($image: img('offline/no_icon_light.svg') !important);
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.label-box-container.portrait {
    .label-text {
        @include font-size(s);
        line-height: 0.9rem;
    }
}
</style>
