<template>
    <div @click="$emit('clickTimer')" class="timer" :class="[$mq, 'status-' + data.status, { active: data.status > 0 }]">
        <div v-if="data.same_product_status != null" :class="['rel-status', 'status-' + data.same_product_status]"></div>
        <header>
            <img class="img" :src="product.image" />
            <span class="text">{{ product.name }}</span>
        </header>
        <div class="time">
            <template v-if="live">
                {{ timeLeft.h }}:{{ timeLeft.m }}<span class="seconds">:{{ timeLeft.s }}</span>
            </template>
            <template v-else>00:00</template>
        </div>
    </div>
</template>

<script>
import { TIMERPRODUCT_STATUS } from '@/constants'

export default {
    props: {
        data: { type: Object },
        visibilityType: { type: Number }
    },
    data() {
        return {
            now: moment(),
            active: true
        }
    },
    computed: {
        timeLeft: function () {
            var now = this.now
            if (this.visibilityType == 1) {
                var ref_date = this.data.data.date_timestamp
                // if (this.data.status == TIMERPRODUCT_STATUS.PREPARATION) {
                //     ref_date = this.data.prep_date
                // }

                var duration = moment.duration(moment(ref_date * 1000).diff(now))

                var diff = duration.asSeconds()

                var diff_seconds = String(parseInt(diff % 60)).padStart(2, '0')
                var diff_minutes = String(Math.floor((diff % 3600) / 60)).padStart(2, '0')
                var diff_hours = String(Math.floor(diff / 3600)).padStart(2, '0')

                var diff = {
                    h: diff_hours,
                    m: diff_minutes,
                    s: diff_seconds
                }
                return diff
            } else {
                var ref_date = this.data.data.print_date_timestamp
                // if (this.data.status == TIMERPRODUCT_STATUS.PREPARATION) {
                //     ref_date = this.data.prep_date
                // }

                var duration = moment.duration(now.diff(moment(ref_date * 1000)))

                var diff = Math.abs(duration.asSeconds())

                var diff_seconds = String(parseInt(diff % 60)).padStart(2, '0')
                var diff_minutes = String(Math.floor((diff % 3600) / 60)).padStart(2, '0')
                var diff_hours = String(Math.floor(diff / 3600)).padStart(2, '0')
                var diff = {
                    h: diff_hours,
                    m: diff_minutes,
                    s: diff_seconds
                }
                return diff
            }
        },

        live() {
            return this.data.status != TIMERPRODUCT_STATUS.EXPIRED
        },

        product() {
            return this.$store.getters['timerproduct/getProducts'](this.data.reference_id)
        }
    },
    methods: {},
    created() {
        var self = this
        setInterval(function () {
            self.now = moment()
        }, 1000)
    }
}
</script>

<style lang="scss" scoped>
.timer {
    padding: 0 2px;
    overflow: hidden;
    min-width: 0;
    height: 120px;
    background-color: $color-error-500;
    border-radius: 4px;
    position: relative;
    cursor: pointer;

    &.status-0 {
        background-color: $color-error-500;
    }
    &.status-1 {
        background-color: $color-secondary-500;
    }
    &.status-2 {
        background-color: $done;
    }
    &.status-3 {
        background-color: $color-warning-500;
    }

    .rel-status {
        width: 16px;
        height: 16px;
        border: 2px solid #fff;
        background-color: red;
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 100%;

        &.status-0 {
            background-color: $color-error-500;
        }
        &.status-1 {
            background-color: $color-secondary-500;
        }
        &.status-2 {
            background-color: $done;
        }
        &.status-3 {
            background-color: $color-warning-500;
        }
    }

    header {
        width: 100%;
        text-align: center;
        padding: 5px;

        .img {
            width: 35px;
            height: 35px;
            display: block;
            margin: 0 auto;
        }

        .text {
            @include font-size(sm);
            line-height: 16px;
            @include text-ellipsis();
            font-family: $text;
            color: #fff;
            text-align: center;
            width: 100%;
            text-transform: uppercase;
            margin-top: 4px;
            display: block;
            @include text-ellipsis($line: 2);
            white-space: pre-line;
        }
    }

    .time {
        width: 100%;
        font-family: Avenir-Bold;
        text-align: center;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.25);
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 6px 4px;
        font-size: 22px;

        .seconds {
            font-size: 16px;
            color: #fff;
        }
    }
}
</style>
