<template>
    <div class="timers" :class="[$mq]" v-if="show_timers">
        <Topbar :categories="categories" @selectCategory="selectCategory" @addTimer="show_timers = false" @showOptions="isPopupOpen = true"></Topbar>

        <div id="board">
            <Column v-for="column in columns" :visibilityType="currentVisibility" :key="column.id" :data="column" :timers="columnTimers[column.id] ? columnTimers[column.id] : []" @clickTimer="showPopupOptionsTimer"></Column>
        </div>

        <div class="popup-container blocking popup-small" v-if="isPopupOpen" @click="closePopup()">
            <div class="popup">
                <div class="list" :class="{ portrait: isApp || ['portrait'].includes($mq) }">
                    <div class="items-list" :class="{ 'mobile-landscape': isApp || ['portrait'].includes($mq) }">
                        <div class="item-list" v-for="option in popupOptions" @click="changeVisibility(option.value)" :class="[{ selected: currentVisibility === option.value }, { 'non-scroll': popupOptions.length < 6 }]">
                            <span class="selector-color" :style="{ background: currentVisibility === option.value ? '#000' : 'transparent' }" :class="[{ 'no-color': currentVisibility !== option.value }]"></span>
                            <span class="selector-item">{{ option.displayText }}</span>
                        </div>
                    </div>
                    <Button bType="cancel" customClass="selector-popup" :bCallback="closePopup" />
                </div>
            </div>
        </div>

        <div class="popup-container blocking popup-small" v-if="popupTimerOpen" @click="closePopupTimer()">
            <div class="popup">
                <div class="preview">
                    <Preview :refresh="false" :key="keyTimer" :calculate="n" :data="previewTimer"></Preview>
                </div>
                <div class="list" :class="{ portrait: isApp || ['portrait'].includes($mq) }">
                    <Button bType="print" :bLabel="'Imprimir'" customClass="selector-popup" :bCallback="closePopupTimer" />
                    <Button bType="move" :bLabel="'Mover a ...'" customClass="selector-popup" :bCallback="moveTimerNextState" :bCallbackProps="keyTimer" />
                    <Button bType="delete" :bLabel="'Eliminar'" customClass="selector-popup" :bCallback="deleteTimer" :bCallbackProps="keyTimer" />
                    <Button bType="cancel" customClass="selector-popup" :bCallback="closePopupTimer" />
                </div>
            </div>
        </div>
    </div>
    <Products @timerCreated="timerCreated" v-else></Products>
</template>

<script>
import Column from '@/components/domain/timerproduct/column.vue'
import Topbar from '@/components/domain/timerproduct/topbar.vue'
import Products from '@/components/domain/timerproduct/products.vue'
import Button from '@/components/domain/ui/Button.vue'
import Preview from '@/components/domain/label/preview.vue'
import { TIMERPRODUCT_STATUS } from '@/constants'

export default {
    components: { Column, Topbar, Products, Button, Preview },
    name: 'TimerProduct',
    data() {
        return {
            timers: [],
            selectedCategory: false,
            show_timers: true,
            isPopupOpen: false,
            currentVisibility: 1,
            popupOptions: [
                {
                    displayText: this.$t('timersproduct.visibility.remaining_time'),
                    value: 1
                },
                {
                    displayText: this.$t('timersproduct.visibility.useful_life'),
                    value: 2
                }
            ],
            popupTimerOpen: false,
            previewTimer: {},
            keyTimer: 0,
            n: 0
        }
    },
    computed: {
        columns() {
            return this.$store.getters['timerproduct/getStates']
        },
        categories() {
            return this.$store.getters['timerproduct/getCategories']
        },
        columnTimers: function () {
            return this.$store.getters['timerproduct/getTimersByCategory'](this.selectedCategory)
        },
        isApp() {
            return this.$store.getters.getIsApp
        }
    },
    methods: {
        load() {
            let self = this

            this.$overlay.loading()

            // this.$store.dispatch('timerproduct/loadSchema').then(() => {
            this.$store.dispatch('timerproduct/loadTimers').then(() => {
                self.selectCategory(false)
                self.$overlay.hide()
            })
            // })
        },
        selectCategory(category) {
            console.log('selectCategory', category)
            var self = this
            self.$overlay.loading()
            this.$store.dispatch('timerproduct/selectCategory', category).then(() => {
                self.selectedCategory = category
                self.$overlay.hide()
            })
        },
        timerCreated() {
            this.show_timers = true
            // this.load()
        },

        changeVisibility(visibility) {
            this.currentVisibility = visibility
            this.isPopupOpen = false
        },

        closePopup() {
            this.isPopupOpen = false
        },

        showPopupOptionsTimer(id) {
            console.log('showPopupOptionsTimer', id)
            const timers = this.$store.getters['timerproduct/getTimers']
            if (timers[id]) {
                const product = this.$store.getters['timerproduct/getProducts'](timers[id].reference_id)
                console.log('product', product)
                const formatId = product.states[timers[id].state].format
                console.log('formatId', formatId)
                const format = this.$store.getters['printer/getTemplates'](formatId)
                console.log('format', format)
                let data = timers[id].data
                var newPreviewStructure = format.realistic_preview ? JSON.parse(format.realistic_preview) : false
                let newPreviewData = {}

                if (newPreviewStructure) {
                    // console.log('VALUES: ', values)
                    newPreviewStructure.fields.forEach((el) => {
                        let key = el.field

                        if (el.f_id && values[el.f_id]) {
                            const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                            var val = values[el.f_id]
                            log('F_ID: ', el.f_id)
                            log('Val: ', val)
                            var value = val

                            if (values[el.f_id].match(/[\{\}\[\]]/gi)) {
                                let m
                                var index = ''
                                while ((m = regex.exec(val)) !== null) {
                                    // This is necessary to avoid infinite loops with zero-width matches
                                    if (m.index === regex.lastIndex) {
                                        regex.lastIndex++
                                    }

                                    // The result can be accessed through the `m`-variable.
                                    m.forEach((match, groupIndex) => {
                                        index = match.replace(/[\{\}\[\]]/gi, '')
                                        // console.log('MATCH: ', match, index, data[index])
                                        value = value.replace(match, data[index])
                                        if (el.format && !moment(value, el.format, true).isValid()) {
                                            value = moment(value, 'DD/MM/YYYY HH:mm').format(el.format)
                                        }
                                    })
                                }
                                el.current = false
                            }

                            el.value = value
                        } else if (el.value && el.value.match(/[\{\}\[\]]/gi)) {
                            const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                            var val = el.value
                            var value = val
                            // console.log('VAL: ', val, 'VALUE:', value)
                            let m
                            var index = ''
                            while ((m = regex.exec(val)) !== null) {
                                // This is necessary to avoid infinite loops with zero-width matches
                                if (m.index === regex.lastIndex) {
                                    regex.lastIndex++
                                }

                                // The result can be accessed through the `m`-variable.
                                m.forEach((match, groupIndex) => {
                                    index = match.replace(/[\{\}\[\]]/gi, '')
                                    // console.log('MATCH: ', match, index, data[index])
                                    value = value.replace(match, data[index])

                                    if (el.format && !moment(value, el.format, true).isValid()) {
                                        value = moment(value, 'DD/MM/YYYY HH:mm').format(el.format)
                                    }
                                    // console.log('INDEX:', index, 'MATCH: ', match, 'VALUE:', value)
                                })
                            }
                            // console.log('BARCODE ', self.currentStep)
                            // console.log('BARCODE ', self.data[self.currentStep])

                            // var currentStep = self.data.filter((step) => {
                            //     // console.log(index, step)
                            //     return step.keyname == index
                            // })

                            // console.log(currentStep)

                            // self.previewPrint[currentRow].push({
                            //     v: value,
                            //     l: length,
                            //     current: index.split('_')[0] == self.data[self.currentStep].keyname.split('_')[0],
                            //     barcode: index.match(/_timestamp/gi) || (currentStep[0] && currentStep[0].type == 'barcode') ? true : false
                            // })
                            el.current = false
                            el.value = value
                        }
                    })
                    newPreviewData = Object.assign({}, newPreviewStructure)
                }

                this.previewTimer = newPreviewData
                this.keyTimer = id
                this.n++
            }
            this.popupTimerOpen = true
        },

        closePopupTimer() {
            this.popupTimerOpen = false
        },

        moveTimerNextState(timerId) {
            console.log('moveTimerNextState', timerId)
            const timers = this.$store.getters['timerproduct/getTimers']
            if (timers[timerId]) {
                let timer = timers[timerId]
                let currentState = timer.state
                if (timer.status > TIMERPRODUCT_STATUS.EXPIRED) {
                    //get state where id is equal to timer.state from this.columns
                    let posState = this.columns.findIndex((state) => state.id == timer.state)
                    console.log('posState', posState)
                    if (posState < this.columns.length - 2) {
                        let bFound = false
                        let nextState = false
                        while (!bFound && posState < this.columns.length - 2) {
                            nextState = this.columns[posState + 1].id
                            bFound = typeof this.$store.getters['timerproduct/getProducts'](timer.reference_id).states[nextState] !== 'undefined'
                            posState++
                        }
                        if (bFound) {
                            let self = this
                            this.$store.dispatch('timerproduct/moveTimerNextState', { timer: timer, nextState: nextState }).then((response) => {
                                if (response) {
                                    let msg = self.$tc('config.printer.success_save', parseInt(timer.data.quantity))
                                    if (parseInt(timer.data.quantity) > 1) {
                                        msg = msg.replace('[num_copies]', timer.data.quantity)
                                    }
                                    msg = msg.replace('[product]', timer.data.product)
                                    self.$snackbar.progress({
                                        closeable: true,
                                        message: msg
                                    })
                                } else {
                                    self.$snackbar.error({
                                        message: self.$t('config.printer.error_print_default'),
                                        closeable: true
                                    })
                                }
                            })
                        } else {
                            //TODO
                            alert('ESTE TIMER NO TIENE MAS ESTADOS')
                        }
                    } else {
                        //TODO
                        alert('Ya no se puede mover mas el timer')
                    }
                } else {
                    //TODO
                    alert('Ya no se puede mover mas el timer')
                }
            }
            this.closePopupTimer()
        },

        deleteTimer(timerId) {
            console.log('deleteTimer', timerId)
            var self = this
            this.$store.dispatch('timerproduct/deleteTimer', { id: timerId }).then(() => {
                self.$snackbar.progress({
                    closeable: true,
                    message: 'Timer eliminado'
                })
                self.closePopupTimer()
            })
        }
    },
    created() {
        this.load()
    },
    mounted() {},
    watch: {}
}
</script>

<style lang="scss" scoped>
.timers {
    #board {
        padding: 8px;
        border-radius: 4px;
        width: 100%;
        height: calc(100% - 54px);
        display: flex;
        position: absolute;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .popup-container {
        position: fixed;
    }

    .popup-container .items-list {
        padding-right: 0px !important;
        margin: 0px !important;
    }

    .popup-container.popup-small .popup {
        max-width: 350px;
    }

    .popup-container.blocking {
        z-index: 44;
    }

    .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        .selector-cancel-btn {
            width: 100%;
            margin: 0px;
        }
        .item-list {
            padding: 8px;
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;
            width: 98%;
            margin: 8px 0;
            border: 1px solid $color-neutral-300;
            @include border-radius(4px);
            transition: all 0.3s ease-in-out;

            &.non-scroll {
                width: 100%;
            }

            &:hover {
                background-color: $color-selector;
            }

            &.selected {
                background-color: $color-neutral-200;
            }

            &.clear-value {
                color: $color-letter-selector;
                width: 100%;
                padding-right: 8px;
                font-size: 1.1em;
                padding-left: 38px;
                padding-right: 8px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                overflow-wrap: anywhere;
            }
        }

        &.portrait {
            .clear-value {
                font-size: 0.8em;
            }
        }
        .items-list {
            margin-top: 2px;
            max-height: 280px;
            overflow-y: auto;
            padding-right: 8px;
            width: 100%;

            &.mobile-landscape {
                max-height: 205px;
                .selector-item {
                    font-size: 0.8em;
                    min-height: 1.2em;
                    color: $color-letter-selector;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    overflow-wrap: anywhere;
                }
            }
            .selector-color {
                padding: 5px;
                @include border-radius(100%);
                margin-left: 8px;

                &.no-color {
                    border: 1px solid $color-selector2;
                    position: relative;
                }
            }

            .selector-item {
                font-size: 1.1em;
                min-height: 1.2em;
                color: $color-letter-selector;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
